<template>
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Total Shops</span>
						<div class="text-900 font-medium text-xl">{{ allStores.length }} items</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round"
						style="width:2.5rem;height:2.5rem">
						<i class="pi pi-inbox text-cyan-500 text-xl"></i>
					</div>
				</div>
				<span class="text-green-500 font-medium"> </span>
				<span class="text-500"> </span>
			</div>
		</div>

		<div class="col-12 lg:col-6 xl:col-3">
		</div>

		<div class="col-12 xl:col-6">
		</div>
		<div class="col-12 xl:col-6">
		</div>
	</div>
</template>

<script>
import apiStore from './../store/index';
export default {
	async mounted() {
	},
	created() {
	},
	computed: {
		allStores: function (){
			return apiStore.getters["storeinternal/getAllStores"];
		}
	},
	data() {
		return {
		}
	},
	methods: {
	}
}
</script>